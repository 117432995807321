@import '../../../scss/shared';

.download-modal {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }
}
