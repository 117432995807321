@import '../../scss/shared';

.navbar {
  min-height: fit-content;
  flex-direction: column;
}

.navbar-language {
  padding: 0 $content-full-padding;
  background-color: #dbdcdd;
  display: flex;
  justify-content: flex-end;

  @include screen($desktop-sm) {
    padding: 0 $content-small-screen-size-padding;
  }
}

.navbar-menu {
  display: grid;
  grid-template-columns: 25% 1fr;
  padding: 15px $content-full-padding;
  border-bottom: 2px solid rgba($plain-black, 0.2);
  min-height: 81px;

  @include screen($desktop-sm) {
    gap: 10px;
    padding: 15px $content-small-screen-size-padding;
  }

  @include screen($tablet-lg - 1) {
    grid-template-columns: 1fr;
  }

  button {
    font-size: 15px;
    word-break: keep-all;

    @include screen($desktop-lg) {
      margin: 0 10px 0 0;
      font-size: 14px;
    }

    @include screen($tablet-lg - 1) {
      margin: 20px 0;
      padding: 10px 15px;
    }
  }

  .navbar-start {
    cursor: pointer;
  }

  .navbar-item {
    font-weight: $bold;
    color: $plain-black;
    padding: 10px 10px 0;
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;

    @include screen($desktop-lg) {
      margin: 0 10px 0 0;
      padding-top: 12px;
      font-size: 14px;
    }

    @include screen($tablet-lg - 1) {
      margin-bottom: 10px;
    }

    &:hover {
      color: $plain-black;
    }

    &.is-active {
      background-color: transparent;
    }

    &.is-active::after {
      display: block;
      content: '';
      width: 30px;
      height: 5px;
      background-color: $plain-black;
      margin-top: 10px;
    }
  }
}
