@import '../../../scss/shared';

.delete-patient-modal {
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;

  &__title {
    padding-bottom: 20px;
    font-size: 22px;
  }

  &__instructions {
    padding-bottom: 10px;
  }

  &__warning {
    padding-bottom: 25px;
  }

  &__additional-warnings {
    padding-bottom: 25px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 25px;
    text-align: left;
    list-style-type: disc;

    &:empty {
      display: none;
    }
  }

  &__button-row {
    display: flex;
    justify-content: center;
  }

  &__button-row .btn {
    margin-left: 20px;
    margin-right: 20px;
  }
}
