@import '../../scss/shared';

.footer {
  padding: 30px $content-full-padding;
  width: 100%;
  margin-top: auto;
  flex: 0 1 40px;
  align-self: flex-end;

  a {
    color: $plain-black;
  }

  &-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-start {
    max-width: 50%;

    &__top {
      font-weight: $bold;
      margin-bottom: 20px;
    }

    &__bottom {
      a {
        margin-right: 20px;

        &:first-child::after {
          display: inline-block;
          content: '';
          width: 2px;
          height: 17px;
          background-color: $plain-black;
          margin-left: 15px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  &-end {
    text-align: right;

    &__directory {
      font-weight: $bold;
      margin-bottom: 20px;
    }
  }
}
