@import '../../scss/settings/colors';

.error-message {
  margin: auto;
  padding: 0 30px 20px;
  position: fixed;
  background: rgba(0 0 0 / 70%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
}

.error-message-container {
  background: $plain-white;
  padding: 40px;
  text-align: center;
  margin: auto;
  max-width: 450px;
  border-radius: 6px;
}

.error-message-text {
  font-size: 18px;
  color: $plain-black;
}

.error-message-button-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
