/* stylelint-disable media-feature-range-notation */
@import '../../scss/shared';

.info {
  background-color: $gamboge;
  padding: 50px 100px;
  color: $plain-black;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: fit-content;
  margin-top: 44%;
  max-width: 720px;
}

.login-container {
  background-image: url('https://genetherapy-web-dev.azurewebsites.net/content/img/bg-login-1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.login-box {
  padding: 55px 75px;
  background-color: $plain-white;
  border-radius: 5px;
  margin: 100px 60px 66px 0;
  height: fit-content;
}

.login-header {
  text-align: left;
}

.login-header img {
  width: 95%;
  margin-bottom: 45px;
}

.language-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    padding: 8px 30px;
  }
}

@media screen and (max-width: 1280px) {
  .login-box {
    margin: 40px auto;
    padding: 55px 20px;
  }
}

@media screen and (max-width: 1024px) {
  .login-container {
    flex-direction: column;
  }

  .login-box {
    margin: 40px auto;
    padding: 40px 20px;
  }
}

@media screen and (min-width: 1280px) {
  .info {
    margin-top: 37%;
  }
}
