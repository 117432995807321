@import '../../../scss/shared';

/* stylelint-disable selector-class-pattern */
/* stylelint-disable max-nesting-depth */

// Override default ReactModal values
.ReactModal {
  &__Overlay {
    z-index: 9990;
    background-color: #333333 !important;
  }

  &__CloseBtn {
    cursor: pointer;
    position: absolute;
    right: -12px;
    top: -16px;
  }

  &__Content {
    z-index: 9991;
    padding: 20px 45px 45px !important;
    box-shadow: 0 0 10px 0 $plain-black;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: flex-start;
    overflow: visible;
    max-width: 580px;

    &:focus {
      outline: none;
    }

    &__Title {
      font-size: 20px;
      line-height: 30px;
      padding-top: 24px;
      color: $plain-black;
      margin-bottom: 15px;

      h2 {
        font-weight: $bold;
      }
    }

    &__Body {
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}
