@import '../../scss/shared';

.help-pages {
  p {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 24px;
  }

  ul,
  ol {
    margin-left: 1.2em;
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 15px;
  }

  ul {
    margin-left: 40px;
    list-style: disc;
  }

  ol {
    list-style: decimal;

    li::marker {
      font-weight: bold;
    }
  }

  li {
    margin-bottom: 5px;
  }

  strong {
    color: $plain-black;
    font-weight: $bold;
  }

  h2,
  h3,
  h4 {
    color: $plain-black;
    font-weight: $bold;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 19px;
  }

  h4 {
    font-size: 16px;
  }

  table {
    font-size: 14px;
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 20px;
    border: 1px solid #888888;

    thead {
      font-weight: $bold;
    }

    tr,
    td,
    th {
      border: 1px solid #888888;
      padding: 5px;
    }
  }

  .cookie-table {
    table {
      table-layout: fixed;

      th:nth-child(3) {
        width: 66%;
      }
    }
  }

  .mb-5px {
    margin-bottom: 7px;
  }

  .jobcode-tb {
    margin-top: 25px;
  }

  .centered {
    margin: 0 auto;
    width: fit-content;
  }

  .right-aligned {
    width: fit-content;
    margin-left: auto;
  }
}
