@import '../../../scss/shared';

.invite-token-modal {
  &__description-text {
    font-size: 16px;
  }

  &__form {
    opacity: 0;
    height: 0;
    transition: height 0.5s, opacity 0.2s 0.2s;

    &.show {
      opacity: 1;
      height: 175px;
    }

    form {
      padding: 10px 20px;
    }

    input {
      margin-bottom: 0;
    }

    span {
      position: absolute;
    }

    button {
      margin: auto;
      margin-top: 35px;
    }

    .success {
      margin: auto;
      text-align: center;
      margin-top: 15px;
      opacity: 0.8;
      color: rgb(41 114 41);
    }
  }

  &__button-bar {
    justify-content: center;
    padding-top: 15px;
    gap: 15px;
    align-items: center;
  }

  &__token-box {
    width: 100%;
    background-color: $cultured;
    padding: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;

    &__copy-token-btn {
      cursor: pointer;
    }

    &__copy-notice {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}
