@import '../../../scss/shared';

.btn {
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.is-small {
    max-height: 44px;
  }

  &.is-140-wide {
    width: 140px;
  }

  &.is-medium-fixed-width {
    min-width: 150px;
  }

  &-outlined {
    border-style: solid;
    border-width: 2px;

    &--black {
      @include button-theme($plain-white, $plain-black, $plain-black);
      border-radius: 2px;
      padding: 0 30px;
      font-weight: $bold;
    }

    &--gray {
      @include button-theme($plain-white, $plain-black, rgba($plain-black, 0.1));
      border-radius: 3px;
      padding: 10px 20px;
    }

    &--orange {
      border-color: $gamboge;
      background-color: $plain-white;
      color: $gamboge;
      border-radius: 3px;
      padding: 0 10px;

      &:hover,
      &:focus,
      &:active {
        color: $plain-white !important;
        background-color: $gamboge;
      }

      img {
        background-color: $plain-white;
      }
    }
  }

  &-no-border {
    border: 0;

    &--black {
      @include button-theme(transparent, $plain-black);
    }
  }

  &-filled {
    &--orange {
      @include button-theme($gamboge, $plain-black, rgba($plain-black, 0.1));
      border-radius: 2px;
      font-weight: $bold;
      font-size: 20px;
      line-height: 24px;
      padding: 20px 40px;

      img {
        width: 20px;
        height: 24px;
      }
    }

    &--black {
      @include button-theme($plain-black, $plain-white);
      padding: 10px 30px;
    }

    &--gray {
      background-color: #f0f0f0;

      &:hover,
      &:focus,
      &:active {
        background-color: #e4e4e4;
      }
    }
  }

  &--has-icon {
    display: flex;
    justify-content: space-between;

    img {
      margin-right: 10px;
    }
  }

  &.is-reversed {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.has-content-centered {
    justify-content: center;
  }

  &.vertical-align-with-icon {
    line-height: 16px;
  }

  &.bordered {
    border: 2px solid rgba($plain-black, 0.1);
  }
}
