/* stylelint-disable scss/no-global-function-names */
@import '../../scss/shared';

.loading-spinner {
  position: relative;
  top: calc(50% - 100px);
  z-index: 9991;
  margin: auto;
  width: 300px;
  text-align: center;
  animation: fade-spinner 2s forwards;
  opacity: 0;
}

.loading-spinner__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9990;
  animation: fade-overlay 2s forwards;
  background-color: transparentize($lapis-lazuli, 1);

  .loading-spinner {
    animation: fade-spinner 3s forwards;
    opacity: 0;
  }
}

.loading-spinner__local {
  top: calc(50% - 40px);
  position: relative;

  .loading-spinner {
    animation: fade-spinner 1s forwards;
    opacity: 0;
  }
}

@keyframes fade-overlay {
  0% {
    background-color: transparentize($lapis-lazuli, 1);
  }

  50% {
    background-color: transparentize($lapis-lazuli, 1);
  }

  100% {
    background-color: transparentize($lapis-lazuli, 0.7);
  }
}

@keyframes fade-spinner {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
