.plan-modal {
  align-items: flex-start;
  max-width: fit-content;

  &__summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__button-bar {
    justify-content: center;
    padding-top: 15px;
  }

  &__exercise-list {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__exercise-card {
    cursor: default !important;
  }
}
