@import '../../scss/shared';

.patient-card {
  $p: &;
  cursor: pointer;
  background-color: $cultured;
  border: 2px solid rgba($plain-black, 0.1);
  margin-top: 30px;
  height: 112px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(.is-add) {
    margin-left: 46px;
  }

  &__initials-container {
    width: 80px;
  }

  &__initials {
    text-overflow: clip;
    overflow: hidden;
    white-space: nowrap;
    width: 72px;
    text-align: center;
    font-size: 33px;
    margin: 10px auto;
    color: $plain-white;
    line-height: 62px;
  }

  &__image {
    width: 82px;
    height: 82px;
    border-radius: 50px;
    background-color: #32021f;
    position: relative;
    left: -142px;
    margin-right: -142px;
  }

  &__details {
    padding: 30px 0 30px 30px;
    width: 225px;

    &__name {
      font-weight: $bold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__age {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__delete {
    padding: 20px;
    margin-left: auto;
  }

  &::before {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: $plain-white;
    display: block;
    content: '';
    position: relative;
    left: -55px;
    border-right: 2px solid rgba($plain-black, 0.2);
  }
}
