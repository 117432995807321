@import '../../../scss/shared';

.publish-box {
  width: 100%;
  background-color: $cultured;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
}

.publish-check {
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}
