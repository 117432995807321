body {
  margin: 0;
  font-family: $default-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $plain-black !important;

  strong {
    color: inherit;
  }

  .solid-background {
    height: 100vh;
    background-color: #333333;
  }

  .subheading {
    font-size: 20px;
    line-height: 20px;
    font-weight: $bold;
    margin: 20px 0;
  }

  .light-blue {
    color: #7ed8fb;
    font-size: 17px;
    font-weight: normal;
  }

  .content-container {
    margin: 30px 5%;
    max-width: 100% !important;
  }

  .section-title {
    font-size: 20px;
    line-height: 20px;
    font-weight: $bold;
    margin-bottom: 20px;
  }

  .divider {
    border-bottom: 2px solid rgba($plain-black, 0.2);
    margin: 25px 0;
  }

  .prereadonly {
    white-space: pre-wrap;
    font-family: 'arial', sans-serif;
  }

  .react-select__control {
    margin: 1em 0;

    &:hover {
      border-color: $gamboge;
    }

    &--is-focused {
      border-color: $gamboge;
    }
  }
}
