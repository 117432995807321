@import '../../scss/shared';

.exercise-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba($plain-black, 0.1);
  width: 430px;
  margin: 20px 30px 20px 0;
  user-select: none;
  cursor: pointer;
  background-color: $plain-white;

  &__image {
    max-width: 130px;
    max-height: 130px;
    display: cover;
  }

  &__details {
    margin-left: 15px;
    margin-top: 10px;
    line-height: 24px;
    width: 100%;
    padding: 0 10px;
    overflow: hidden;

    &__title {
      font-weight: $bold;
      margin-bottom: 5px;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'arial', sans-serif;
      overflow: hidden;
    }

    .added-label {
      background-color: $lapis-lazuli;
      color: $plain-white;
      padding: 5px 10px;
      margin: 5px;
      font-size: 12px;
      line-height: 13px;
      height: fit-content;
    }

    &__description {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'arial', sans-serif;
      overflow: hidden;
      width: 75%;
    }

    button {
      min-width: max-content;
      margin-left: 20px;
    }
  }

  &.full-width {
    margin: 10px;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
}
