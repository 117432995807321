@import '../../../scss/shared';

.tab-list {
  &__container {
    display: flex;
    gap: 6em;
    border-top: 1px solid rgba(0 0 0 / 10%);
    border-bottom: 1px solid rgba(0 0 0 / 10%);
    padding: 10px 0;
    margin-bottom: 2em;

    @include screen($desktop-sm) {
      gap: $content-small-screen-size-padding;
    }
  }

  &__btn {
    font-weight: bold;
    border: 0;
    background-color: transparent;
    padding: 10px 30px;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &__btn_active {
    background-color: $lapis-lazuli;
    color: $plain-white;
  }
}
