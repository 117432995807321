@import '../../../scss/shared';

input[type='text'] {
  background-color: $cultured;
  width: 100%;
  margin: 10px 0;

  &:focus {
    border-color: $gamboge;
    box-shadow: 0 0 0 0.125em rgba($gamboge, 0.2);
  }
}

.label {
  font-weight: normal;
  margin: 5px 0 0 !important;
}
