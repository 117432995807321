@import '../../../scss/shared';

.tab-content {
  &__heading {
    font-weight: $bold;
    font-size: 20px;
  }
}

.plan-activity {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;

  &__card {
    border: 1px solid rgba(0 0 0 / 10%);
    padding: 15px 25px;
    display: grid;
    grid-template-columns: 50% 45% 1fr;
    align-items: center;

    &__date {
      font-weight: $bold;
    }

    &__btn {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $gamboge;
      border: 0;
      cursor: pointer;

      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        border-right: 0.2em solid $plain-white;
        border-top: 0.2em solid $plain-white;
        transform: rotate(45deg);
        margin-top: 0.2rem;
        margin-right: 0.4em;
      }
    }
  }
}

.physios {
  &__card-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
  }

  &__card {
    border: 2px solid rgba(0 0 0 / 10%);
    background-color: #fafafa;
    padding: 20px;

    &__name {
      font-weight: $bold;
      font-size: 20px;
    }
  }
}
