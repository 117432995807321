@import '../../../scss/shared';

.edit-exercises {
  &__exercises-list {
    margin-top: 20px;
  }

  &__add-exercise {
    display: flex;
    flex-direction: row;
  }

  &__add-exercise-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: $bold;
    margin-bottom: 5px;
  }

  &__add-exercise-info {
    display: flex;
    flex-grow: 1;
  }
}
