$spacing-xs: 15px;
$spacing-sm: 30px;
$spacing-md: 45px;
$spacing-lg: 60px;
$spacing-xl: 90px;
$spacing-xx: 120px;
$content-full-padding: 10rem;
$content-right-padding: 11rem;
$content-half-padding: 5rem;
$content-small-screen-size-padding: 3rem;
