// Colors
$plain-white: #ffffff;
$plain-black: #000000;
$cultured: #fafafa;
$gamboge: #ec9a1e;
$lapis-lazuli: #0460a9;
$pale-blue: #5c9fcb;
$dark-cultured: #e1e1e1;
$charcoal: #535353;

// Color Uses

// Colors List
$colors: (
  'plain-white': $plain-white,
  'plain-black': $plain-black,
  'cultured': $cultured,
  'gamboge': $gamboge,
  'dark-cultured': $dark-cultured,
  'charcoal': $charcoal,
  'pale-blue': $pale-blue,
);
