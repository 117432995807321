/* stylelint-disable media-feature-range-notation */
/*! @license CookieBar.css, Copyright 2018 earthware.
All rights reserved. website: https://www.earthware.co.uk, contact: support@earthware.co.uk */
/*  SCSS - Following BEM naming standard  */

body {
  /*iphone font scaling fix*/
  /* stylelint-disable */
  -webkit-text-size-adjust: 100%;
  /* stylelint-enable */
}

.has-ew-cookie-bar {
  transition: margin 500ms ease-in-out;
}

.ew-cookie-bar {
  background-color: #f2f2f2;
  color: #333333;
  padding: 10px;
  left: 0;
  position: fixed;
  width: 100%;
  min-height: 80px;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 5px 5px rgba(0 0 0 / 40%);
  border-top: 1px solid #aeaeae;
  font-size: 0.95rem;
  line-height: normal;
  z-index: 10000;

  &__container {
    margin: 0 auto;
    width: 80%;
  }

  &__cookie-message-body {
    width: 80%;
    display: inline-block;

    span {
      display: block;
      margin: 10px;
    }
  }

  &__cookie-button-body {
    width: 20%;
    display: inline-block;
    position: absolute;
    margin: 10px 0;
  }

  &__button {
    border-width: 0;
    width: 100px;
    height: 55px;
    background-color: #169bd5;
    border-radius: 5px;
    box-shadow: none;
    font-weight: 400;
    font-style: normal;
    font-size: 0.9rem;
    text-align: center;
    margin: 0 20px 10px 10px;

    &--accept {
      background: #0094ff;
      color: #ffffff;
    }

    &--decline {
      background: #808080;
      color: #ffffff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &--show-top {
    top: 0;
  }

  &--hide-top {
    top: -300px;
  }

  &--show-bottom {
    bottom: 0;
  }

  &--hide-bottom {
    bottom: -300px;
  }

  &--animate {
    transition:
      top 500ms ease-in-out,
      bottom 500ms ease-in-out;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.85rem;
    min-height: 70px;

    &__button {
      width: 100px;
      height: 50px;
      margin: 0 10px 10px 10px 10px;
    }

    &__container {
      width: 90%;
    }

    &__cookie-message-body {
      width: 75%;
    }

    &__cookie-button-body {
      width: 25%;
    }
  }

  @media screen and (max-width: 820px) {
    &__button {
      font-size: 0.8rem;
      width: 90px;
      height: 45px;
    }

    &__container {
      width: 95%;
    }

    &__cookie-message-body {
      width: 75%;
    }

    &__cookie-button-body {
      width: 25%;
    }
  }

  @media screen and (max-width: 760px) {
    &__cookie-message-body {
      width: 65%;
    }

    &__cookie-button-body {
      width: 35%;
    }
  }

  @media screen and (max-width: 545px) {
    &__cookie-message-body {
      width: 95%;
    }

    &__cookie-button-body {
      display: block;
      position: unset;
      width: 95%;
    }

    &__container {
      width: 95%;
    }
  }
}

.ew-policy-cookie-table {
  border-collapse: collapse;
  width: 95%;
  word-wrap: break-word;
  word-break: break-word;

  tbody {
    display: table-row-group;
    vertical-align: middle;
  }

  td,
  th {
    height: 48px;
    padding: 10px;
  }

  th {
    text-align: left;
    background: #597f8a;
    color: #ffffff;
    min-width: 200px;
  }

  tr {
    background-color: #fbfbfb;
    border-top: 1px solid #808080;
  }

  @media screen and (max-width: 1100px) {
    width: 95%;
  }

  @media screen and (max-width: 780px) {
    width: 100%;
    font-size: 13px;
    table-layout: fixed;

    td,
    th {
      padding: 5px;
    }

    th {
      min-width: unset;
    }
  }
}
