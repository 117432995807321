@import '../../../scss/shared';

.publish-plan {
  &__overview-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: $bold;
    margin-bottom: 10px;
  }

  &__overview-info {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__overview-entries {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
  }

  &__clickable-link {
    cursor: pointer;
    line-height: 28px;
    font-size: 14px;
  }

  &__clickable-link:hover {
    text-decoration: underline;
  }

  &__button-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }
}
