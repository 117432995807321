@import '../../scss/shared';

.step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  border: 1px solid rgba($plain-black, 0.2);
  margin-bottom: 15px;

  &__add-exercise {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
