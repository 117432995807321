@import '../../scss/shared';

.plan-exercises__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 22px;
    line-height: 28px;
    margin: 20px 0;
    font-weight: $bold;
  }
}

.exercises-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
