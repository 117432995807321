@import '../../scss/shared';

.header {
  height: 130px;
  background-color: $cultured;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    padding-right: $content-right-padding;

    @include screen($desktop-sm) {
      padding: 0 3rem;
    }
  }
}

.page-title {
  max-width: fit-content;
  padding: 40px 0 25px;

  h1 {
    font-size: 32px;
    line-height: 36px;
    font-weight: $bold;
  }

  &.with-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
  }

  &.is-active {
    border-bottom: 4px solid $gamboge;
  }
}
