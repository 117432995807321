@import '../scss/shared';

// note the layout and related nav/header/footer components
// use the shared spacing padding variables to ensure content
// is aligned left and right across all components
.main-layout {
  &__page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__left-pattern {
    min-width: $content-half-padding;
    max-width: $content-half-padding;
    background-image: url('../images/pattern-2.png');
  }

  &__main {
    display: flex;
    flex-direction: row;
    width: 100vw;
    flex: 1 1 auto;

    &-content {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.page-layout {
  &__hero {
    width: 100%;
    min-height: 400px;
    background-image: url('../images/hero-home-2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;

    &-title {
      margin-top: 130px;
      margin-bottom: 50px;
      width: 41%;
      height: fit-content;
      background-color: $gamboge;
      padding: 40px $content-half-padding;

      h1 {
        font-size: 38px;
        line-height: 43px;
        font-weight: $bold;
        max-width: 333px;
        text-align: left;
        margin-bottom: 1rem;
      }

      p {
        max-width: 333px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  &__content {
    padding: 2rem $content-half-padding;
    padding-right: $content-right-padding;
  }
}
