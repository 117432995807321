@import '../../scss/shared';

.token-container {
  margin-top: 40px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $lapis-lazuli;
  color: $plain-white;
  border-radius: 2px;

  h2 {
    font-weight: $bold;
  }

  @include screen($desktop-sm) {
    flex-direction: column;
  }
}
