@import '../../../scss/shared';

.review-date {
  &__overview-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: $bold;
    margin-bottom: 5px;
  }

  &__overview-info {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

// calendar.css overrides
.react-calendar {
  margin: 20px auto;
}

.react-calendar__tile--now {
  background: $plain-white !important;
}

.react-calendar__tile:enabled:hover {
  background: #e6e6e6 !important;
}

.react-calendar__tile--active,
.react-calendar__tile--active.react-calendar__tile:hover {
  background: #1087ff !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__label span {
  font-weight: $bold;
  font-size: 16px;
}
