@import '../../scss/shared';

.verify-email-message {
  position: absolute;
  top: 15%;
  transform: translate(-50%, 0);
  left: 50%;
  padding: 35px 50px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verify-email-message-text-wrapper {
  margin: 30px 0 40px;
}

.verify-email-message-text {
  font-size: 18px;
  text-align: center;
  font-weight: $bold;
}

.verify-email-message-logo {
  margin: 20px 0;
}
