/* stylelint-disable scss/no-global-function-names */
@mixin button-theme(
  $bg-color,
  $txt-color,
  $brd-color: $bg-color,
  $bg-hover-color: darken($bg-color, 5%),
  $txt-hover-color: $txt-color,
  $brd-hover-color: $brd-color
) {
  background-color: $bg-color;
  border-color: $brd-color;
  color: $txt-color;

  &:hover {
    background-color: $bg-hover-color;
    border-color: $brd-hover-color;
    color: $txt-hover-color;
  }

  &[disabled] {
    cursor: default;
    background-color: $bg-color;
    border-color: rgba($brd-color, 0.3);
    color: rgba($txt-color, 0.3);
  }

  &:focus,
  &:active,
  &:focus:not(:active) {
    color: $txt-color !important;
    box-shadow: none !important;
    border-color: $brd-color !important;
  }
}

@mixin screen($screen-size) {
  @media (max-width: $screen-size) {
    @content;
  }
}
