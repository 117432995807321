/* stylelint-disable media-feature-range-notation */
/* stylelint-disable selector-not-notation */
@import '../../scss/shared';

.home-button-wrapper {
  display: flex;
  gap: 1em;
}

// TODO should be able to do this via Button - see P0936-505
.home-outlined-button {
  font-size: 20px;
  padding: 8px 20px !important;
}

.home-add-patient-button {
  color: $plain-white !important;
  padding: 8px 20px !important;
  font-weight: 500 !important;
}

.patients-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.75rem;

  @include screen($desktop-sm) {
    grid-template-columns: 1fr 1fr;
  }
}

.home-loading-spinner-container {
  margin-top: 30px;
}

@media screen and (min-width: 1900px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1720px;
  }
}
