@import '../../../scss//shared';

.edit-current-plan {
  &__overview-title {
    font-size: 18px;
    line-height: 28px;
    font-weight: $bold;
    margin-bottom: 5px;
  }

  &__overview-last-published {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  &__overview-review {
    font-size: 16px;
    line-height: 24px;
    font-weight: $bold;
    margin-bottom: 5px;
  }

  &__exercises-list {
    margin-top: 20px;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
