@import '../../scss/shared';

.sidebar {
  &__item {
    padding: 11px;
    cursor: pointer;
    user-select: none;

    &.is-active {
      background-color: #e6eff6;
      border-left: 3px solid #0460a9;
      font-weight: $bold;
    }
  }
}
